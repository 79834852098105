import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade' // see https://www.react-reveal.com/examples/
import Layout from '../components/Layout'
import Button from '../components/Button'

const Wrapper = styled.div`
  background-color: #122A59;
  font-family: 'PT Sans Caption', sans-serif;
	display: flex;
	align-items: center;
	flex-direction: column;
  text-align: center;
  padding: 1rem;
  animation: hide;
  width:100%;
`

const Header = styled.h1`
  margin-top: 0;
  background-color:#ffff;
  padding: 1rem;
  width:100%;
`

const Subheader = styled.h2`
  color: #ffff;
  margin:0;
  font-size: 2rem;
`

const Text = styled.p`
  color: #ffff;
  margin-bottom: 24px;
`

const Logo = styled.img.attrs({
  alt: 'logo'
})`
  width: 100%;
  max-height: 50%;
`

const CarCrash = ({data: {markdownRemark: {frontmatter}}}) =>
  <Layout frontmatter={{...frontmatter, backgroundColor: '#122A59'}}>
    <Wrapper>
      <Fade bottom ssrReveal>
        <div>
          <Header><Logo src={frontmatter.logo.publicURL} /></Header>
          <Subheader>
            <a href='tel:956-968-9556'><Logo src={frontmatter.image.publicURL} /></a>
          </Subheader>
          <Text>{frontmatter.text}</Text>
          <section className='w-100 flex-column flex justify-center items-center'>
            <Button href={frontmatter.link} color='#472828' backgroundColor='#DFC75E'>{frontmatter.buttonText}</Button>
          </section>
        </div>
      </Fade>
    </Wrapper>
  </Layout>

export default CarCrash

export const pageQuery = graphql`
  query CarCrashBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        templateKey
        title
        description
        text
        buttonText
        link
        image {
          publicURL
        }
        logo {
          publicURL
        }
        favicon {
          publicURL
        }
      }
    }
  }
`